import React, { useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import Select from 'react-select';

const ShareAccessModal = ({
  token,
  frontend_url,
  label_show_token,
  label_hide_token,
  label_open_share_modal,
  label_modal_header,
  label_copy,
  label_copy_and_close,
  label_expired,
  data = [],
  expired = false,
}) => {
  const [showToken, setShowToken] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [values, setValues] = useState([]);
  const modalRef = useRef();

  const onCopyButtonClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setShowToken((prevValue) => !prevValue);
  };

  const onOpenModal = () => {
    event.stopPropagation();
    event.preventDefault();

    setShowModal(true);
  };

  const onHandleClose = () => {
    setShowModal(false);
  };

  const generatedUrl = () => {
    const filteredValues = values.filter(Boolean);

    const tokenPart = `?token=${token}`;

    if (!filteredValues.length) {
      return `${frontend_url}${tokenPart}`;
    }

    return `${[frontend_url, ...filteredValues].join('/')}${tokenPart}`;
  };

  const onCopy = () => {
    copy(generatedUrl());
  };

  const onCopyAndClose = () => {
    onCopy();
    onHandleClose();
  };

  return (
    <div className='d-flex flex-column'>
      <div className='mb-3'>
        <a href='#' onClick={onCopyButtonClick} className='text-primary'>
          {showToken ? label_hide_token : label_show_token}
        </a>
        <br />
        {showToken && <p>{token}</p>}
      </div>
      <hr />
      <div>
        {expired ? (
          <p className='text-danger mb-0 text-uppercase'>{label_expired}</p>
        ) : (
          <>
            <a href='#' onClick={onOpenModal} className='text-primary'>
              {label_open_share_modal}
            </a>
            <Modal show={showModal} onHide={onHandleClose} ref={modalRef}>
              <Modal.Header closeButton>
                <Modal.Title>{label_modal_header}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {data &&
                  data.map((collectionObject, index) => {
                    const { label, data: collectionData = [] } =
                      collectionObject;

                    const options = collectionData.map(
                      ({ name: label, slug: value }) => {
                        return {
                          label,
                          value,
                        };
                      }
                    );

                    return (
                      <Form.Group
                        className='mb-2'
                        key={index}
                        controlId={index}
                      >
                        <Form.Label>{label}</Form.Label>
                        <Select
                          options={options}
                          isSearchable
                          isClearable
                          onChange={(value) => {
                            setValues((prevValues) => {
                              const nextValues = [...prevValues];
                              nextValues[index] = value ? value.value : '';

                              return nextValues;
                            });
                          }}
                        />
                      </Form.Group>
                    );
                  })}
              </Modal.Body>
              <Modal.Footer>
                <div className='d-flex flex-column w-100'>
                  <div className='overflow-auto'>
                    <p className='text-secondary'>{generatedUrl()}</p>
                  </div>

                  <div className='mt-3 d-flex justify-content-end'>
                    <Button
                      variant='secondary'
                      onClick={onCopy}
                      className='mr-3'
                    >
                      {label_copy}
                    </Button>
                    <Button
                      variant='primary'
                      onClick={onCopyAndClose}
                      className='ml-3'
                    >
                      {label_copy_and_close}
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default ShareAccessModal;
