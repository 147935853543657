import React, { useEffect, useState } from "react";
import Dropzone from 'react-dropzone';
import * as ActiveStorage from 'activestorage'
import axios from 'axios';
import copy from 'copy-to-clipboard';

class ActiveStorageUploader {
    constructor(progressCallback) {
        this.progressCallback = progressCallback;
    }

    directUploadWillStoreFileWithXHR = (request) => {
        request.upload.addEventListener("progress", (event) => this.progressCallback(event));
    };
}

const DropdownMenuItem = ({ name, url }) => {
    const onClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if(!url) {
            return;
        }

        copy(url);
    };

    return (
        <a class="dropdown-item" href="#" onClick={onClick}>
            {name}
        </a>
    )
}

const FileUrlVariants = ({ file }) => {
    const [urls, setUrls] = useState(null);

    const getFileVariantsFromServer = (signedId) =>
        axios.get('/api/active_storage/get_image_variants/' + signedId, {
            headers: {
                'Accept': 'application/json',
                'Api-Version': '1.0'
            }
        });

    const onDropdownButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        getFileVariantsFromServer(file.signedId).then(({data}) => {
            setUrls(data)
        }).catch((error) => {
            console.error(error)
        });
    }

    if (!file) {
        return null
    }

    return (
        <div>
            <button
                className="btn btn-sm btn-secondary dropdown-toggle url-buttons"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={onDropdownButtonClick}
            >
                URLs
            </button>
            <div className="dropdown-menu">
                <DropdownMenuItem url={urls && urls.thumb_url} name="Thumb (180x180)" />
                <DropdownMenuItem url={urls && urls.medium_url} name="Medium (600x600)" />
                <DropdownMenuItem url={urls && urls.blog_url} name="Blog (1200x900)" />
                <DropdownMenuItem url={urls && urls.url} name="Original" />
            </div>
        </div>
    )
} 

export const ActiveStorageDropzone = (props) => {
    const [files, setFiles] = useState([])
    const [uploadsInProgress, setUploadsInProgress] = useState(0)
    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        let currentFiles = [];
        if (!props.existing_files) {
            return;
        }

        props.existing_files.forEach((existingFile) => {
            let file = {signedId: existingFile.signed_id};
            if (existingFile.preview_image) {
                file.previewImage = existingFile.preview_image;
            }
            if (existingFile.preview_text) {
                file.previewText = existingFile.preview_text;
            }

            currentFiles.push(file);
        });

        setFiles(currentFiles);
    }, [props.existing_files])

    const removeFileFromServer = (signedId) => {
        axios.delete('/ez_on_rails/active_storage/blobs/' + signedId).then();
    };

    const removeFile = (event, signedId) => {
        removeFileFromServer(signedId);

        const currentFiles = files.filter((file) => signedId !== file.signedId);
        setFiles([...currentFiles]);

        event.stopPropagation();
    };

    const onDirectUploadProgress = (event) => {
        if (event.loaded / event.total >= 0.9999999) {
            console.log("File upload completed.");
        }
    };

    const onDropAccepted = (acceptedFiles) => {
        setErrorText(null);

        if (!acceptedFiles.length) {
            return;
        }

        if (props.max_files) {
            const maxNewFiles = props.max_files - (files.length + uploadsInProgress);

            if (maxNewFiles < acceptedFiles.length) {
                showError(props.max_files_error || "No more files allowed.")
            }

            acceptedFiles = acceptedFiles.slice(0, maxNewFiles);
        }

        if (props.max_size) {
            const sizeFilteredFiles = acceptedFiles.filter((file) => file.size <= props.max_size);
            if (sizeFilteredFiles.length < acceptedFiles.length) {
                showError(props.max_size_error || "Some files were to large.")
            }
            acceptedFiles = sizeFilteredFiles;
        }

        setUploadsInProgress((uploadsInProgress) => uploadsInProgress + acceptedFiles.length);

        acceptedFiles.forEach((acceptedFile) => {
            let uploader = new ActiveStorageUploader(onDirectUploadProgress)
            let upload = new ActiveStorage.DirectUpload(acceptedFile, '/rails/active_storage/direct_uploads', uploader);
            upload.create((error, blob) => {
                setUploadsInProgress((uploadsInProgress) => uploadsInProgress - 1)

                if (error) {
                    console.log("Image Error:", error);
                } else {
                    let file = {signedId: blob.signed_id}

                    if (acceptedFile.type.includes("image")) {
                        file.previewImage = URL.createObjectURL(acceptedFile);
                    } else {
                        file.previewText = acceptedFile.name;
                    }

                    files.push(file);
                    setFiles([...files]);
                }
            });
        });
    };

    const onPaste = (event) => {
        if (!event.clipboardData) {
            return;
        }

        let items = event.clipboardData.items;
        if (items === undefined) {
            return;
        }

        let pastedFiles = [];
        for (let i = 0; i < items.length; i++) {
            if (props.accept && !items[i].type.match(props.accept)) {
                setErrorText(props.invalid_format_error || "Invalid format")
                continue;
            }

            const file = items[i].getAsFile();
            if (file) {
                pastedFiles.push(file);
            }
        }

        onDropAccepted(pastedFiles);
    };

    const previews = files.map(file => {
        return (
            <div key={file.signedId} className="card mb-4 w-25" style={{flex: "0 0 auto"}}>
                <div className={"card-header p-1"}>
                    <FileUrlVariants file={file} />
                    <button onClick={(event) => removeFile(event, file.signedId)} type="button" className="close"
                            aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className={"d-flex justify-content-center align-items-center w-100 h-100"}>
                    {file.previewImage &&
                    <img
                        src={file.previewImage}
                        className={"rounded m-auto d-block p-1 mw-100 m-0"}
                    />}
                    {file.previewText &&
                    <span className="p-4">
                            {file.previewText}
                        </span>}
                </div>
            </div>
        )
    });

    let progressSpinners = [];

    for (let i = 0; i < uploadsInProgress; i++) {
        progressSpinners.push(
            <div key={i} className="card mb-4 w-25" style={{flex: "0 0 auto"}}>
                <div className={"d-flex justify-content-center align-items-center w-100 h-100"}>
                    <div className="text-center p-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const signedIdInputs = files.map(file => {
        return <input key={file.signedId} type="hidden" id={props.input_id} name={props.input_name}
                      defaultValue={file.signedId} readOnly={true}/>
    });

    const showError = (error) => {
        setErrorText(error)
    };

    const onDropRejected = (fileRejections, event) => {
        const errorCodes = fileRejections.map((fileRejection) => fileRejection.errors[0].code)

        if (errorCodes.indexOf('file-invalid-type') > -1) {
            showError(props.invalid_format_error || 'Invalid format')
        } else if (errorCodes.indexOf('file-too-large') > -1) {
            showError(props.max_size_error || "Some of those files you dropped are too large.")
        } else {
            showError(props.max_files_error || "You can not drop more files.")
        }
    }

    return (
        <div>
            <input type="text"
                   className="w-100 p-2 active-storage-dropzone-pastezone-container"
                   value={props.text_pastezone || "Copy and paste some files here"}
                   onPaste={(event) => onPaste(event)}
                   readOnly/>
            <Dropzone
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
                multiple={props.multiple}
                maxSize={props.max_size}
                maxFiles={props.max_files}
                accept={props.accept}
            >
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()} className="p-4 active-storage-dropzone-dropzone-container">
                            <input {...getInputProps()} />

                            {signedIdInputs}

                            <p className={"m-0"}>{props.text_dropzone || "Drag 'n' drop some files here, or click to select files"}</p>

                            {previews.length > 0 &&
                            <aside className={"card-deck justify-content-center w-100 m-4"}>
                                {previews}
                            </aside>
                            }
                            {progressSpinners.length > 0 &&
                            <aside className={"card-deck justify-content-center w-100 m-4"}>
                                {progressSpinners}
                            </aside>
                            }

                        </div>
                    </section>
                )}
            </Dropzone>
            {errorText ? <div className="pt-1 active-storage-dropzone-error">
                {errorText}
            </div> : null}
        </div>
    );
}

export default ActiveStorageDropzone;
