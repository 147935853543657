import React, { useEffect } from 'react';

export const TrumbowygWrapper = () => {
  useEffect(() => {
    $('.trumbowyg').each(function(i) {
      return $(this).trumbowyg({
        btns: [
          ['viewHTML'],
          ['undo', 'redo'],
          ['formatting'],
          ['strong', 'em', 'del'],
          ['superscript', 'subscript'],
          ['fontsize'],
          ['fontfamily'],
          ['indent', 'outdent'],
          ['lineheight'],
          ['foreColor', 'backColor'],
          ['link'],
          ['insertImage'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['insertAudio'],
          ['noembed'],
          ['preformatted'],
          ['specialChars'],
          ['emoji'],
          ['table'],
          ['tableCellBackgroundColor', 'tableBorderColor'],
          ['removeformat'],
          ['fullscreen']
        ],
        plugins: {
          resizimg: {
            minSize: 64,
            step: 16
          },
          fontfamily: {
            fontList: [
              { name: 'Lato', family: 'Lato, sans-serif' }
            ]
          },
        },
        semantic: { 'del': 's' },
        removeformatPasted: true,
        tagsToRemove: ['script', 'link']
      });
    });

    return () => {
      $('.trumbowyg').each(function() {
        $(this).trumbowyg('destroy');
      });
    }
  }, []);

  return null;
}

export default TrumbowygWrapper;
