import React, {useEffect, useState} from 'react'

export const DurationSelect = (props) => {
    const [value, setValue] = useState(props.default_value || 'P');
    const [years, setYears] = useState(0);
    const [months, setMonths] = useState(0);
    const [weeks, setWeeks] = useState(0);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        if (!props.default_value) {
            return;
        }

        const withoutP = props.default_value.replace("P", "");

        const parts = withoutP.split('T');
        const datePart = parts[0]
        const timePart = parts.length > 1 ? parts[1] : "";

        const dateMatches = datePart.match(/((\d)+Y)?((\d+)M)?((\d)+W)?((\d)+D)?/)
        setYears(dateMatches[2] ? dateMatches[2] : 0)
        setMonths(dateMatches[4] ? dateMatches[4] : 0)
        setWeeks(dateMatches[6] ? dateMatches[6] : 0)
        setDays(dateMatches[8] ? dateMatches[8] : 0)

        const timeMatches = timePart.match(/((\d)+H)?((\d+)M)?((\d)+S)?/)
        setHours(timeMatches[2] ? timeMatches[2] : 0)
        setMinutes(timeMatches[4] ? timeMatches[4] : 0)
        setSeconds(timeMatches[6] ? timeMatches[6] : 0)
    }, [props.default_value]);

    useEffect(() => {
        let res = "P";
        if (years > 0)  res = `${res}${years}Y`;
        if (months > 0)  res = `${res}${months}M`;
        if (weeks > 0)  res = `${res}${weeks}W`;
        if (days > 0)  res = `${res}${days}D`;
        if (hours > 0 || minutes > 0 || seconds > 0) {
            res = `${res}T`;
            if (hours > 0)  res = `${res}${hours}H`;
            if (minutes > 0)  res = `${res}${minutes}M`;
            if (seconds > 0)  res = `${res}${seconds}S`;
        }

        setValue(res);
    }, [years, months, weeks, days, hours, minutes, seconds])

    const onChangeYears = (e) => {
        setYears(parseInt(e.currentTarget.value, 10));
    }

    const onChangeMonths = (e) => {
        setMonths(parseInt(e.currentTarget.value, 10));
    }

    const onChangeWeeks = (e) => {
        setWeeks(parseInt(e.currentTarget.value, 10));
    }

    const onChangeDays = (e) => {
        setDays(parseInt(e.currentTarget.value, 10));
    }

    const onChangeHours = (e) => {
        setHours(parseInt(e.currentTarget.value, 10));
    }

    const onChangeMinutes = (e) => {
        setMinutes(parseInt(e.currentTarget.value, 10));
    }

    const onChangeSeconds = (e) => {
        setSeconds(parseInt(e.currentTarget.value, 10));
    }

    const yearsArray = [];
    const lastYear = props.max_years || 10;
    for (let i = 0; i <= lastYear; i++) {
        yearsArray.push(i);
    }

    const monthsArray = []
    for (let i = 0; i <= 11; i++) {
        monthsArray.push(i)
    }

    const weeksArray = []
    for (let i = 0; i <= 4; i++) {
        weeksArray.push(i)
    }

    const daysArray = []
    for (let i = 0; i <= 6; i++) {
        daysArray.push(i)
    }

    const hoursArray = []
    for (let i = 0; i <= 11; i++) {
        hoursArray.push(i)
    }

    const minutesArray = []
    for (let i = 0; i <= 59; i++) {
        minutesArray.push(i)
    }

    const secondsArray = []
    for (let i = 0; i <= 59; i++) {
        secondsArray.push(i)
    }

    return <div className="duration-select-container">
        <input type="hidden" value={value} id={props.id} name={props.name} />

        <div className="duration-select-selection-container">
            <label className="duration-select-selection-label">{props.label_years || 'Years'}:</label>
            <select className="duration-select-selection-select"
                    onChange={onChangeYears}
                    value={years}>
                {
                    yearsArray.map((val) => {
                        return <option key={ val } className="duration-select-selection-option" value={val}>{val}</option>
                    })
                }
            </select>
        </div>

        <div className="duration-select-selection-container">
            <label className="duration-select-selection-label">{props.label_months || 'Months'}: </label>
            <select className="duration-select-selection-select"
                    onChange={onChangeMonths}
                    value={months}>
                {
                    monthsArray.map((val) => {
                        return <option key={ val } className="duration-select-selection-option" value={val}>{val}</option>
                    })
                }
            </select>
        </div>

        <div className="duration-select-selection-container">
            <label className="duration-select-selection-label">{props.label_weeks || 'Weeks'}: </label>
            <select className="duration-select-selection-select"
                    onChange={onChangeWeeks}
                    value={weeks}>
                {
                    weeksArray.map((val) => {
                        return <option key={ val } className="duration-select-selection-option" value={val}>{val}</option>
                    })
                }
            </select>
        </div>

        <div className="duration-select-selection-container">
            <label className="duration-select-selection-label">{props.label_days || 'Days'}: </label>
            <select className="duration-select-selection-select"
                    onChange={onChangeDays}
                    value={days}>
                {
                    daysArray.map((val) => {
                        return <option key={ val } className="duration-select-selection-option" value={val}>{val}</option>
                    })
                }
            </select>
        </div>

        <div className="duration-select-selection-container">
            <label className="duration-select-selection-label">{props.label_hours || 'Hours'}: </label>
            <select className="duration-select-selection-select"
                    onChange={onChangeHours}
                    value={hours}>
                {
                    hoursArray.map((val) => {
                        return <option key={ val } className="duration-select-selection-option" value={val}>{val}</option>
                    })
                }
            </select>
        </div>

        <div className="duration-select-selection-container">
            <label className="duration-select-selection-label">{props.label_minutes || 'Minutes'}: </label>
            <select className="duration-select-selection-select"
                    onChange={onChangeMinutes}
                    value={minutes}>
                {
                    minutesArray.map((val) => {
                        return <option key={ val } className="duration-select-selection-option" value={val}>{val}</option>
                    })
                }
            </select>
        </div>

        <div className="duration-select-selection-container">
            <label className="duration-select-selection-label">{props.label_seconds || 'Seconds'}: </label>
            <select className="duration-select-selection-select"
                    onChange={onChangeSeconds}
                    value={seconds}>
                {
                    secondsArray.map((val) => {
                        return <option key={ val } className="duration-select-selection-option" value={val}>{val}</option>
                    })
                }
            </select>
        </div>
    </div>
}

export default DurationSelect